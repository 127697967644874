/**
 * Search widget
 *
 * @since   1.0.0
 * @package aaa
 */
.widget-search {
	display: flex;
	flex-flow: row wrap;
	justify-content: space-between;
	padding: bsu(1/2) bsu(2/3);
	background: $white;
	border-radius: $border-radius;
	box-shadow: $card-shadow;

	.widgettitle {
		font-size: rem-calc(20);
	}

	form {
		display: none;
		width: 100%;
		padding: bsu(1/2) 0 bsu(1/3);
	}

	label {
		padding: 0;
	}

	[type="submit"] {
		margin-top: 0;
	}

	@include breakpoint( xlarge ) {
		padding: rem-calc(33) $widget-padding 0;
		box-shadow: none;

		form {
			display: block !important;
		}
	}

	@include breakpoint( xxlarge ) {
		padding-left: $widget-padding-large;
		padding-right: $widget-padding-large;
	}

} // .widget-search

.search-widget-toggle {
	margin: rem-calc(-8);
	padding: rem-calc(14);
	background: none;
	border: none;
	border-radius: $border-radius;

	.icon-filter-bar,
	.icon-filter-circle {
		@include transition-ease-in-out;
		transition-property: fill, stroke;
	}

	&:hover, &:focus {

		.icon-filter-bar {
			fill: $white;
		}

		.icon-filter-circle {
			stroke: $white;
			fill: $blue;
		}
	}

	@include breakpoint( xlarge ) {
		margin-right: rem-calc(-12);
		pointer-events: none;
	}

} // .search-widget-toggle


// For IE
.svg.icon-filter svg {
	height: rem-calc(14);
}
