/**
 * Variables
 *
 * @since   1.0.0
 * @package aaa
 */

/**
 * Primary & Secondary Colors
 */
$red                : #ed1b2e;
$red-dark           : #cc081a;
$blue               : #00529c;
$blue-light         : #3061d1;
$blue-light-mid     : #4470d5;
$blue-lighter       : #1779F2;


/**
 * Neutral & Support Colors
 */
$gray-darker        : #5e5e5e;
$gray-dark          : #9b9b9b;
$gray               : #d7d7d7;
$gray-mid-light     : #dbdbdb;
$gray-light         : #f0f0f0;
$gray-lighter       : #f9f9f9;
$black              : #181818;
$black-pure         : #000000;
$white              : #ffffff;
$transparent        : trans();


/**
 * Fonts
 */
$line-height-large  : 2.5;
$line-height-body   : 2;
$line-height-mid    : 1.5;
$line-height-small  : 1.4;
$global-font-size   : 18px;
$font-sans-serif    : 'Montserrat', sans-serif;
$font-heading       : 'Open Sans', sans-serif;


/**
 * Spacing
 */
$bsu: 1.6667rem;


/**
 * Misc heights/widths
 */
$wrap-max           : 1600px;
$wrap-width         : 92.5%;
$top-strip-height   : 40px;
$header-height      : 64px;
$header-height-desk : 100px;


/**
 * Borders
 */
$border-width       : 3px;
$border-radius      : 4px;


/**
 * Transitions
 */
$speed              : 200ms;
$speed-slow         : 400ms;


/**
 * Shadows
 */
$card-shadow        : 2px 2px 0 0 $gray-mid-light;
$card-shadow-hover  : 0px 4px 30px 0 rgba( $gray-mid-light, 0.75 );
