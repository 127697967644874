.notification {
	background: $black;

	.wrap, a {
		display: flex;
		align-items: stretch;
		color: $white;
	}
}

.notification-wrap {
	width: 100%;
}

.notification_label,
.notification_details {
	// Keep content vertically centered
	display: flex;
	justify-content: center;
	padding: 13px 20px;
}

.notification_label {
	padding: 13px 20px;
	font-weight: bold;
	font-size: 18px;
	line-height: 24px;
	font-family: $font-heading;

	svg {
		margin-right: 10px;
		transform: translateY(3px);
	}
}

.notification_details {
	flex-direction: column;
	padding-right: 50px;
	font-weight: bold;
	font-size: 14px;
	line-height: 17px;
	font-family: $font-sans-serif;
	text-shadow: 0 2px 4px rgba( $black-pure, 0.4 );
}

.-breaking-news {
	.notification_label {
		background: $red;
	}
}

@include breakpoint( large ) {
	.notification-wrap {
		padding: 0 bsu(3/4);
		position: absolute;
		top: 180px; // 40px + padding-top on #content (140px)
		left: 0;
	}

	.notification {
		display: inline-block;
		background: rgba($black, .3);;
	}

	.notification_label,
	.notification {
		border-radius: $border-radius;
	}
}

@include breakpoint( xxlarge ) {
	.notification-wrap {
		padding: 0 bsu(9/8);
	}
}