/**
 * Asset single
 *
 * @since   1.0.0
 * @package aaa
 */
.entry-footer .downloads {

	[role="button"] {
		margin-right: 0;
	}

	@include breakpoint( medium ) {
		margin-top: bsu(-1/2);
	}
}

.single-aaa_asset {

	.embed-container {
		margin-bottom: bsu(1);
	}

	#featured-image-wrap a[role="button"] {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateX( -50% ) translateY( -50% );
	}

	.entry-date {
		display: block;
	}

	.entry-footer {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;
		justify-content: space-between;
	}

	.date-downloads-wrap {
		flex: 1 0 auto;
		max-width: 100%;
	}

	@include breakpoint( xxlarge ) {

		aside.sidebar {
			margin-left: bsu(2.5);
		}
	}
}
