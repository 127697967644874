/**
 * Visitor "notice"
 *
 * @since   1.0.0
 * @package aaa
 */
.notice {
	display: inline-block;
	position: relative;
	margin-bottom: bsu(1/2);
	padding: bsu(3/5) bsu(3/4) bsu(1/2) bsu(1.75);
	font-size: rem-calc(16);
	line-height: $line-height-small;
	color: $gray-dark;
	border: 1px solid $gray-light;
	border-radius: $border-radius;

	.svg-inline--fa {
		position: absolute;
		left: bsu(1/2);
		top: bsu(3/4.5);
	}

	p:first-of-type {
		margin-top: 0;
	}

	@include breakpoint( medium ) {
		margin-bottom: bsu(1);
		padding-left: bsu(2);
		padding-right: bsu(1);
		font-size: rem-calc(18);

		.svg-inline--fa {
			left: bsu(3/4);
		}
	}
}
