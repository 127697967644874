/**
 * Breadcrumb (via Yoast)
 *
 * @since 1.0.0
 */
.breadcrumb {

	span,
	a,
	.breadcrumb_last {
		display: inline-block;
	}

	a,
	.breadcrumb_last {
		padding-left: bsu(1/3);
		padding-right: bsu(1/3);
		color: $gray-dark;
	}

	a {
		transition-property: color;
		@include transition-ease-in-out;

		&:hover {
			color: $blue;
		}
	}

	.breadcrumb_last {
		cursor: default;
	}

	.svg-inline--fa {
		color: $gray;
	}
}
