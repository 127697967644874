/**
 * Navigation
 *
 * @since   1.0.0
 * @package aaa
 */
$toggle-width : 50px;
$toggle-space : 10px;

header[role="banner"] > div > nav {
	width: calc( 100% - ( #{$header-height} - 8px ) );
	line-height: $line-height-mid;
	background: $blue;

	&, .toggle-sub {
		display: block;
	}

	ul {
		margin: 0;
		padding: 0;
		width: 100%;
	}

	li {
		display: flex;
		flex-flow: row wrap;
		position: relative;
	}

	.menu-item-has-children > a {
		width: calc( 100% - #{$toggle-width + $toggle-space} );
	}

	.sub-toggled > .toggle-sub {
		transform: rotateX( 180deg );
	}

	a {
		width: 100%;
		color: $white;
	}

	.menu a {
		display: block;
		flex: 0 1 auto;
		font-size: rem-calc(14);
		padding: bsu(1/4) bsu(2/3);
	}

	.toggle-sub {
		flex: 0 1 auto;
		width: $toggle-width;
		margin-left: $toggle-space;
		padding: 0;
		background: none;
		border-color: transparent;
		border-radius: 0;
	}

	.sub-menu {
		order: 1;
		display: none;
	}


	/**
	 * Desktop
	 */
	@include breakpoint( menu-break ) {
		flex: 0 0 auto;
		display: flex;
		position: static;
		margin-left: auto;
		width: auto;
		transform: none;
		overflow: visible;
		background: none;

		ul {
			@include flex-row;
		}

		li:hover, li.focus {

			> .toggle-sub {
				transform: translateY( -50% ) rotateX( 180deg );
			}

			> .sub-menu {
				left: 0;
				opacity: 1;
			}
		}

		.menu {

			// restrict top-level link width + center them
			> li {
				max-width: rem-calc(200);

				> a {
					@include flex-row( true );
					color: $black;
				}

				&:hover, &:focus, &.focus {
					.toggle-sub {
						color: $white;
					}
				}
			}

			a {
				padding: bsu(2/3) bsu(3/4);
			}

		} // .menu

		.menu-item-has-children > a {
			width: 100%;
			padding-right: $toggle-width - ( $toggle-space / 2 );
		}

		// undo mobile accordion flip
		.sub-toggled > .toggle-sub {
			transform: none;
		}

		.toggle-sub, .sub-menu {
			position: absolute;
		}

		.toggle-sub {
			top: calc( 50% + 1px );
			right: 0;
			margin: 0;
			background: none;
			border: none;
			color: $red;
			transform: translateY( -50% );
			pointer-events: none;
			transition-property: transform, color;
			@include transition-ease-in-out;
		}

		.sub-menu {
			display: block !important; // override slideToggle display: none
			top: 100%;
			left: -99999px;
			width: 100%;
			min-width: rem-calc(200);
			background: $blue-light-mid;
			opacity: 0;
			transition: opacity ease-in-out 300ms;

			.toggle-sub {
				color: $white;
				transform: translateY( -50% ) rotate( 90deg );
			}

			.sub-menu {
				top: 0;
			}

			li:hover, li.focus {

				> .sub-menu {
					right: 100%;
					left: auto;
				}

				> .toggle-sub {
					transform: translateY( -50% ) rotate( -90deg );
				}
			}

		} // .sub-menu

	} // menu break


	@include breakpoint( xxlarge ) {

		.menu {

			> .menu-item-has-children > .toggle-sub {
				right: rem-calc(12);
			}

			a {
				padding: bsu(2/3) bsu(1.25);
			}
		}
	}

} // header[role="banner"]


/**
 * Primary menu
 */
#primary-menu {
	padding-top: bsu(1/4);
	padding-bottom: bsu(1/4);
	font-family: $font-heading;

	> li {

		> a {
			padding-top: bsu(1/2.25);
			padding-bottom: bsu(1/2.25);
			font-size: rem-calc(20);
			font-weight: 600;
		}

		&.sub-toggled {
			background-color: $blue-light;
		}
	}

	li {
		@include transition-ease-in-out;
		transition-property: background-color;
	}

	.sub-menu {
		padding-bottom: bsu(2/3);

		a {
			padding: bsu(1/3) bsu(1.125);
		}
	}


	@include breakpoint( menu-break ) {
		padding: 0;

		> li > a,
		.sub-menu a {
			@include transition-ease-in-out;
		}

		> li {

			&:hover, &:focus, &.focus {
				background-color: $blue-light;

				> a {
					color: $white;
				}
			}

			> a {
				font-size: rem-calc(16);
			}
		}

		> li > .sub-menu {
			padding: bsu(2/3) 0;
		}

		.sub-menu {
			padding: 0;

			a {
				padding: bsu(1/2) bsu(3/4);

				&:hover {
					background: $blue-light;
				}
			}
		}
	} // bp: menu-break


	@include breakpoint( xxlarge ) {

		> li {

			&.menu-item-has-children > a {
				padding-right: rem-calc(50);
			}

			> a {
				font-size: rem-calc(20);
			}
		}

		.sub-menu a {
			padding: bsu(1/2.5) bsu(1.25);
		}
	}

} // #primary-menu


/**
 * Mobile secondary menu
 */
#secondary-menu {
	padding-top: bsu(1/2);
	padding-bottom: bsu(1/2);
	border-top: 1px solid rgba( $white, 0.2 );

	a {
		padding-top: bsu(1/8);
		padding-bottom: bsu(1/8);
		font-size: rem-calc(13);
	}

	.sub-menu {
		padding: bsu(1/4) 0;

		a {
			padding-left: bsu(1.5);
			padding-right: bsu(1.5);
			font-size: rem-calc(12);
		}
	}

} // #secondary-menu


/**
 * Socials
 */
header[role="banner"] .socials {
	margin-top: bsu(-1/6);
	margin-left: bsu(1/3);
	margin-right: bsu(1/3);

	a {
		width: auto;
	}
}


#secondary-menu,
header[role="banner"] .socials {

	@include breakpoint( menu-break ) {
		display: none;
	}
}
