/**
 * Social icon navigation
 *
 * @since   1.0.0
 * @package aaa
 */
.socials {
    font-size: 0;
    margin: 0 bsu(-1/3);

    a {
        display: inline-block;
        padding: bsu(1/3);
        @include transition-ease-in-out;
        opacity: 1;

        &:hover, &:focus {
            opacity: 0.5;
        }
    }

    .svg-inline--fa {
        font-size: $global-font-size;
    }

    @include breakpoint( medium ) {
        margin-left: bsu(-1/2);
        margin-right: bsu(-1/2);

        a {
            padding: bsu(1/2);
        }

        .svg-inline--fa {
            font-size: rem-calc(18);
        }
    }
}