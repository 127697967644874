/**
 * Entry header/footer/meta
 *
 * @since   1.0.0
 * @package aaa
 */
.single-post .entry-header {
    margin-bottom: bsu(1);

    @include breakpoint( medium ) {
        margin-bottom: bsu(2);
    }
}

.entry-header,
.entry-footer {
    .entry-date {
		color: $gray-darker;
		font-size: rem-calc(14);
    }
}

.entry-header .term-labels {
    margin-bottom: bsu(1/3);
}

.entry-footer {
    margin-top: bsu(1);
}

.entry-meta {

    .entry-date {
        margin-left: bsu(1);
        line-height: 1.25;
    }

    .entry-header & {
        display: flex;
        margin-top: bsu(1);
    }

    @include breakpoint( medium ) {

        .entry-header & {
            align-items: flex-end;
        }

        .has-headshot + .entry-date {
            margin-bottom: rem-calc(5);
        }
    }

    @include breakpoint( xlarge ) {

        .entry-date {
            margin-left: bsu(2);
            font-weight: 500;
        }

        .entry-footer & {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
}


.swp_social_panel {
    display: block;
    margin-left: auto;
}

.swp_social_panel:not(.nc_floater):not(.swp_social_panelSide) {
    width: auto !important; // need to override crappy widget !important style
    margin-left: -5px; // compensate for icon spacing
    margin-right: -5px; // compensate for icon spacing
}

// .swp_share_button {
//     max-width: 40px;
// }

// .iconFiller {
//     max-width: 30px;
// }