/**
 * Button
 *
 * @since   1.0.0
 * @package aaa
 */

/**
 * Large button
 */
@mixin button-large {
	font-size: rem-calc(18);
	padding: rem-calc(13) rem-calc(49) rem-calc(13);
}


/**
 * Base button hover
 */
@mixin button-hover {
	background-color: $blue-light;
	border-color: $blue-light;
}


/**
 * Red hovered/focused button
 */
@mixin button-red-hover {
	background-color: $red-dark;
	border-color: $red-dark;
}


/**
 * Ghost button hover
 */
@mixin button-ghost-hover {
	color: $blue-light;
	border-color: $blue-light;

	&.red {
		color: $red-dark;
		border-color: $red-dark;
		background-color: transparent;
	}

	&.light {
		color: $black;
		background-color: $white;
		border-color: $white;
	}
}


/**
 * Red button
 */
@mixin button-red {
	background-color: $red;
	border-color: $red;

	&:hover, &:focus {
		@include button-red-hover;
	}
}


/**
 * Light ghost button
 */
@mixin button-ghost-light {
	color: $white;
	border-color: $white;
}


/**
 * Ghost button
 */
@mixin button-ghost {
	color: $blue;
	background-color: transparent;
	border-color: $blue;

	&:hover, &:focus {
		@include button-ghost-hover;
	}

	&.red {
		color: $red;
		border-color: $red;
	}

	&.light {
		@include button-ghost-light;
	}
}


/**
 * "Text link" button hover
 */
@mixin button-text-hover {
	color: $blue-lighter;
}


/**
 * "Bold" modifier to text button
 */
@mixin button-text-bold {
	font-weight: 700;

	.text {
		text-decoration: none;
	}

	.icon {
		top: 0;

		&:first-child {
			margin-right: rem-calc(7);
		}
		&:last-child {
			margin-left: rem-calc(7);
		}
	}
}


/**
 * "Text link" button
 */
@mixin button-text {
	padding-left: rem-calc(14);
	padding-right: rem-calc(14);
	background: transparent;
	border-color: transparent;
	font-weight: 400;
	color: $blue;

	.text {
		text-decoration: underline;
	}

	.icon {
		position: relative;
		top: rem-calc(1);

		&:first-child {
			margin-right: rem-calc(5);
		}
		&:last-child {
			margin-left: rem-calc(5);
		}
	}

	&.bold {
		@include button-text-bold;
	}

	&:hover, &:focus {
		@include button-text-hover;
	}
}


/**
 * Large play button
 */
@mixin button-play-large {

	@include breakpoint( xlarge ) {
		width: rem-calc(120);
		height: rem-calc(72);
		font-size: rem-calc(24);
	}
}


/**
 * "Play" button hover
 */
@mixin button-play-hover {
	background-color: $white;
	border-color: $white;

	.icon {
		color: $blue;
	}
}


/**
 * "Play" button
 */
@mixin button-play {
	position: absolute;
	top: 50%;
	left: 50%;
	padding: 0;
	width: rem-calc(60);
	height: rem-calc(36);
	transform: translateX( -50% ) translateY( -50% );
	border-radius: $border-radius;

	.icon {
		@include transition-ease-in-out;
		transition-property: color;
	}

	&.play-large {
		@include button-play-large;
	}

	&:hover, &:focus {
		@include button-play-hover;
	}
}


/**
 * Negative margins the size of button padding
 *
 * Good for "text" button alignment. Considers border width.
 */
@mixin button-offset-padding {
	margin-left: calc( #{rem-calc(-14)} + #{-$border-width} );
	margin-right: calc( #{rem-calc(-14)} + #{-$border-width} );
}


/**
 * Button contains screen-reader text
 */
@mixin button-sr-text {

	.icon {
		margin-left: 0;
		margin-right: 0;
	}
}


/**
 * Base button
 */
@mixin button {
	@include flex-row( true, true );
	justify-content: center;
	padding: rem-calc(14) rem-calc(41) rem-calc(13);
	text-align: center;
	font-family: $font-heading;
	font-size: rem-calc(14);
	line-height: $line-height-small;
	font-weight: 700;
	background: $blue;
	border: $border-width solid $blue;
	border-radius: 9999px;
	outline: none;
	cursor: pointer;
	transition-property: background-color, border-color, color;

	@include transition-ease-in-out;

	&, &:visited {
		color: $white;
	}

	.icon {
		&:first-child {
			margin-left: rem-calc(-3);
			margin-right: rem-calc(10);
		}
		&:last-child {
			margin-left: rem-calc(10);
		}
	}

	span {
		display: inline-block;
	}

	&:hover, &:focus {
		@include button-hover;
	}

	&.large {
		@include button-large;
	}

	&.red {
		@include button-red;
	}

	&.ghost {
		@include button-ghost;
	}

	&.text {
		@include button-text;
	}

	&.play {
		@include button-play;
	}

	&.offset-padding {
		@include button-offset-padding;
	}

	&.sr-text {
		@include button-sr-text;
	}
}

button, [role="button"], [type="submit"], [type="button"] {
	@include button;
}
