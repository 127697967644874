/**
 * Category name/icon wrapped in color
 *
 * @since   1.0.0
 * @package aaa
 */
.term-labels, .term-label {
	display: flex;
	line-height: 1;

	h1 & {
		display: inline;
	}
}

.term-labels {
	flex-flow: row wrap;
}

.term-label {
	align-items: center;
	justify-content: center;
	margin-right: bsu(1/2);

	&.icon-only,
	&.has-background {
		color: $white;
		border-radius: 999px;
	}

	&.has-background {
		padding: rem-calc(6) rem-calc(11) rem-calc(6) rem-calc(10);
		font-size: rem-calc(13);
		@include no-font-smoothing;
	}

	&.icon-only {
		margin-right: rem-calc(6);
		padding: 0;
		width: rem-calc(24); //rem-calc(30);
		height: rem-calc(24); //rem-calc(30);
		font-size: rem-calc(12); // rem-calc(14)
		text-align: center;

		.svg-inline--fa {
			margin-right: 0;
		}
	}

	.svg-inline--fa {
		margin-right: rem-calc(6);
	}

	@include breakpoint( medium ) {

		&.icon-only {
			margin-right: rem-calc(9);
			width: rem-calc(30);
			height: rem-calc(30);
			font-size: rem-calc(14);
		}
	}
}

.category-background {
	background: $gray-mid-light;
}
