/**
 * Promo for user
 *
 * @since   1.0.0
 * @package aaa
 */
.promo-user {
	text-align: right;

	&:before {
		@include overlay(1);
		background: linear-gradient( to bottom, trans( $black ) 59%, $black 100% );
		opacity: 0.85;
	}

	[data-object-fit="cover"] {
		object-position: 0 10%;
	}

	&, > div, .wrap {
		display: flex;
	}

	> div, .wrap {
		align-items: flex-end;
		justify-content: space-between;
	}

	> div { // For IE flex-child alignment bug
		width: 100%;
		min-height: rem-calc(360);
	}

	.logo-white-icon-only {
		margin-right: bsu(1);
	}


	p {
		margin-top: bsu(1/8);
		font-size: rem-calc(14);

		&:first-of-type {
			margin-top: rem-calc(2);
		}
	}

	a {
		color: $white;
		border-bottom-color: $white;

		&[href^="tel"], &[href^="mailto"] {
			border-bottom-color: transparent;

			&:hover {
				border-bottom-color: $white;
			}
		}

		&:hover {
			color: $white;
		}
	}

	strong {
		font-weight: 600;
	}


	@include breakpoint( large ) {

		> div {
			min-height: rem-calc(420);
		}

		figcaption {
			padding: bsu(1) bsu(3/4);
		}
	}
}
