.flickity-button {
    display: none;

    &,
    &:hover,
    &:focus,
    &:active {
        background: none;
        border: none;
    }
}

.flickity-page-dots {
    bottom: 36px;

    .dot {
        vertical-align: middle;
        height: 12px;
        width: 12px;
        border: 2px solid $white;
        background: none;
        position: relative;
        opacity: 1;
        transition: height 300ms, width 300ms;

        &:after {
            content: '';
            display: block;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background: $red;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0;
            visibility: hidden;
            transition: opacity 300ms, visibility 300ms;
        }

        &.is-selected {
            height: 16px;
            width: 16px;

            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

@include breakpoint( large ) {
    .flickity-button {
        display: block;
        width: 60px;
        height: 60px;
    }

    .flickity-page-dots {
        padding: 0 bsu(3/4);
        text-align: right;
    }

    .notification-wrap {
        padding: 0 bsu(3/4);
        position: absolute;
        top: 180px; // 40px + padding-top on #content (140px)
    }

    .notification {
        display: inline-block;
        background: rgba($black, .3);;
    }

    .notification_label,
    .notification {
        border-radius: $border-radius;
    }

}

@include breakpoint( xxlarge ) {
    .notification-wrap {
        padding: 0 bsu(9/8);
    }

    .flickity-page-dots {
        padding: 0 bsu(9/8);
    }
}