/**
 * MailPoet mixes b/c it doesn't allow us to un-hook/disable styles
 *
 * @unbelievable
 * @since   1.0.0
 * @package aaa
 */

.widget_mailpoet_form {
	padding-bottom: bsu(1/4);

	label {
		padding-left: rem-calc(3);
	}

	[type="submit"] {
		margin: rem-calc(2) 0 0;
		width: auto;
	}

	input {
		border-radius: 9999px;
	}

	@include breakpoint( large ) {
		padding-top: bsu(2/3);
	}

	@include breakpoint( xlarge ) {
		padding-top: rem-calc(33);
		padding-bottom: bsu(1/2);
	}

} // .widget_mailpoet_form


/**
 * Trump the BS!
 */
#content [id^="mailpoet_form_"] {

	/* paragraphs (label + input) */
	.mailpoet_paragraph {
		line-height: $line-height-small; // 20px;
		font-size: rem-calc(14);

		&:first-of-type {
			margin-top: bsu(1/4);
		}

		&:last-of-type {
			margin: 0;
		}

		@include breakpoint( large ) {
			font-size: rem-calc(16);
		}

		@include breakpoint( xxlarge ) {
			line-height: $line-height-body;
		}
	}

	/* labels */
	.mailpoet_segment_label,
	.mailpoet_text_label,
	.mailpoet_textarea_label,
	.mailpoet_select_label,
	.mailpoet_radio_label,
	.mailpoet_checkbox_label,
	.mailpoet_list_label,
	.mailpoet_date_label {
		display:block;
		font-weight:bold;
	}

	/* inputs */
	.mailpoet_text,
	.mailpoet_textarea,
	.mailpoet_select,
	.mailpoet_date_month,
	.mailpoet_date_day,
	.mailpoet_date_year,
	.mailpoet_date {
		display:block;
	}

	.mailpoet_text,
	.mailpoet_textarea {
		width: 100%; //200px;
	}

	.mailpoet_validate_success {
		font-weight: 600;
		color: #468847;
	}

	.mailpoet_validate_error {
		color: $red; //#B94A48;
	}

	.mailpoet_form_loading {
		width: 30px;
		text-align: center;
		line-height: normal;
	}

	.mailpoet_form_loading > span {
		width: 5px;
		height: 5px;
		background-color: #5b5b5b;
	}

	.parsley-errors-list,
	input.parsley-error,
	.parsley-required, .parsley-custom-error-message {
		color: $red;
	}

	.parsley-errors-list {
		margin: rem-calc(-10) 0 rem-calc(20);
		font-weight: 600;
	}

	input.parsley-error {
		border-color: $red;
		border-width: $border-width;
		background: $white;
	}
}
