/**
 * Flickity theming
 *
 * @since   1.0.0
 * @package aaa
 */
.slider {
	width: 100%;
	background: $black;

	.post-edit-link {
		position: absolute;
		right: 0;
		bottom: 0;
	}

	.term-labels {
		display: inline-flex;
		margin-left: bsu(1/3);
	}

	.term-label {
		font-size: rem-calc(14);
	}
}

.slide-cell, .slide-wrap {
	width: 100%;
}

.slide-wrap {
	@include flex-row;
	align-items: flex-end;
	position: relative;
	width: 100%;
	height: 200px; // for IE
	@include fp( min-height, 400, 550 );

	&:after {
		@include overlay;
		background: linear-gradient( to top, rgba( $black, 0.85 ), trans( $black ) );
	}
}

.slide_content {
	margin-bottom: 60px;
	color: $white;
	z-index: 1;
}

.slide_name, .slide-date {
	line-height: $line-height-small;
}

.slide_name {
	display: block;
	max-width: rem-calc(850);
}


.slide_date {
	display: inline-block;
	font-size: rem-calc(14);

	.svg-inline--fa {
		margin-right: rem-calc(5);
	}
}

@include breakpoint( large ) {

	.slide_content {
		margin-bottom: rem-calc(40);
		padding: 0 bsu(3/4);
	}

	.slide_date {
		font-size: 18px;

		.svg-inline--fa {
			display: none;
		}
	}
}

@include breakpoint( xxlarge ) {

	.slide_content {
		padding: 0 bsu(9/8);
	}
}
