/**
 * Search page
 *
 * @since   1.0.0
 * @package search
 */

header.search,
header.category {
	margin-top: bsu(1/2);
}

header.search {

	h1 {
		@include fp( font-size, 17, 30 );
	}

	@include breakpoint( medium ) {
		margin-top: bsu(1);

		h1, strong {
			font-weight: 600;
		}
	}
}

header.category {
	@include breakpoint( medium ) {
		margin-top: bsu(1.5);
	}
}

body.search,
body.blog,
body.archive {

	[role="main"],
	aside.sidebar {
		padding-top: bsu(1/2);
		padding-bottom: bsu(1/2);

		@include breakpoint( medium ) {
			padding-top: bsu(3/4);
			padding-bottom: bsu(3/4);
		}
	}
}

.widget-asset-results .widgettitle,
.articles-heading {
	margin-bottom: bsu(1/2);

	@include breakpoint( medium ) {
		margin-bottom: bsu(2/3);
	}
}


[role="main"] .view-all-button {
	margin-top: bsu(1/4);

	@include breakpoint( medium ) {
		margin-top: 0;
	}
}