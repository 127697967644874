/**
 * Footer
 *
 * @since   1.0.0
 * @package aaa
 */

footer[role="contentinfo"] {
	position: relative;
	flex-shrink: 0;
	font-size: rem-calc(12);
	text-align: center;

	&, a {
		color: $white;
	}

	.about-menu {
		padding: bsu(1) 0;
		background: $black;
	}

	.about {

		h3, h3 + p {
			margin-top: bsu(3/4);
		}

		p {
			text-align: left;
		}
	}

	.logo-white, h3 {
		@include center-block;
		width: 85%;
	}

	.logo-white {
		max-width: rem-calc(255);
	}

	.menu,
	.toggle-sub {
		display: none;
	}

	.menu {
		margin: bsu(1) bsu(-1/4) bsu(-1);
		line-height: $line-height-small;

		> li {
			display: inline-flex;
			flex-flow: column nowrap;
			margin-bottom: bsu(1);

			> a {
				font-family: $font-heading;
				font-size: rem-calc(18);
				font-weight: 600;
			}
		}

		a {
			display: block;
			padding: bsu(1/4);
			@include transition-color( $blue-lighter );

			&[href="#"]:hover {
				color: $white;
				cursor: default;
			}
		}

	} // .menu

	.sub-menu {
		display: flex;
		flex-flow: column wrap;
		max-height: rem-calc(160);

		li {
			display: block;
		}

		a {
			max-width: rem-calc(140);
			margin-right: bsu(1.5);
		}
	}

	.copyright-social {
		padding: bsu(1/2) 0 bsu(1);
		background: $blue;
	}

	.socials {

		.svg-inline--fa {
			font-size: rem-calc(18);
		}

		& + p {
			margin-top: bsu(1/2.5);
		}
	}


	/**
	 * Breakpoints
	 */
	@include breakpoint( medium ) {
		text-align: left;

		.about {
			max-width: rem-calc(440);
		}

		.logo-white, h3 {
			margin-left: 0;
			width: auto;
		}

		.menu {
			display: block;
		}

		.copyright-social {
			padding: bsu(1/4) 0;
			min-height: rem-calc(60);

			.wrap {
				display: flex;
				flex-flow: row-reverse nowrap;
				align-items: center;
				justify-content: space-between;
			}
		}

		.socials + p {
			margin-top: 0;
		}

	} // bp: medium


	@include breakpoint( large ) {

		.about-menu {
			padding: bsu(1.25) 0 bsu(1.75);

			.wrap {
				display: flex;
			}
		}

		.about {
			flex: 1 1 33%;
		}

		.menu {
			flex: 0 1 auto;
			margin-top: 0;
			margin-left: auto;
			padding-left: bsu(1);
		}

	} // bp: large


	@include breakpoint( xlarge ) {
		font-size: rem-calc(14);

		.about-menu {
			padding-bottom: bsu(3);
		}

		.about {
			margin-top: bsu(1/5);

			h3 + p {
				margin-top: bsu(1.75);
			}

			p {
				line-height: $line-height-large;
				font-size: rem-calc(12);
			}
		}

		.menu {
			margin-top: bsu(-1/3);

			> li {
				min-width: rem-calc(180);

				> a {
					margin-bottom: bsu(1/4);
					font-size: rem-calc(24);
				}
			}

			a {
				padding-top: bsu(1/3);
				padding-bottom: bsu(1/3);
			}

		} // .menu

		.sub-menu {
			max-height: rem-calc(210);

			a {
				max-width: rem-calc(165);
			}
		}

	} // bp: xlarge


	@include breakpoint( xxxlarge ) {

		.menu > li {
			padding-right: bsu(1);
			min-width: rem-calc(230);
		}

		.sub-menu a {
			margin-right: bsu(2);
		}
	}

} // footer[role="contentinfo"]
