/**
 * Page Partials
 *
 * @since   1.0.0
 * @package aaa
 */

.page-title {
	margin-bottom: bsu(1/2);

	@include breakpoint( medium ) {
		margin-bottom: bsu(1);
	}
}

// import
@import "search"; // includes stuff shared with category
@import "asset";
@import "home";
