/**
 * Pagination
 *
 * @since 1.0.0
 */

// prev/next buttons
.nav-links {
	font-size: 0;

	.nav-previous, .nav-next {
		display: inline-block;

		&:nth-child(2) {
			margin-left: bsu(1/3);
		}
	}

	a {
		@include button;
	}
}

// numbered
.numbered-pagination {
	margin-top: bsu(3/4);
	width: 100%;
	line-height: 1;
	font-size: rem-calc(14);
	font-weight: 700;
	font-family: $font-heading;

	ul, li, a,
	.current, .dots {
		display: flex;
		flex-flow: row nowrap;
	}

	ul {
		flex-wrap: wrap;
	}

	ul, a,
	.current, .dots {
		justify-content: center;
	}

	li {
		margin: rem-calc(5);
	}

	a, .current, .dots {
		align-items: center;
		padding: rem-calc(7) rem-calc(10);
		min-width: rem-calc(30);
		color: $gray-darker;
	}

	a, .current {
		border: 2px solid #d5d9e0;
		border-radius: $border-radius;
	}

	a:hover,
	.current {
		background: $blue;
		border-color: $blue;
		color: $white;
	}

	a {
		transition-property: color, border-color, background-color;
		@include transition-ease-in-out;
	}

	.dots,
	.prev, .next {
		padding: rem-calc(6);
		min-width: 0;
	}

	.current, .dots {
		cursor: default;
	}

	.prev, .next {
		font-size: 0;

		&, &:hover {
			color: $blue;
			border-color: transparent;
		}

		&:hover {
			background-color: $gray-light;
		}

		.svg-inline--fa {
			font-size: rem-calc(16);
		}
	}

} // .numbered-pagination
