/**
 * WP Triggers plugin theming
 *
 * @since   1.0.0
 * @package aaa
 */
.wptrigger_content {
	margin-top: bsu(1);

	[type="text"] {
		margin-bottom: bsu(1/2);
	}

	[type="submit"] {
		margin-top: 0;
		padding: rem-calc(12) rem-calc(25);
	}

	@include breakpoint( small ) {

		[type="text"],
		[type="submit"] {
			display: inline-block;
			margin-top: 0;
			margin-bottom: 0;
			width: auto;
		}

		[type="text"] {
			max-width: rem-calc(200);
		}

		[type="submit"] {
			margin-left: rem-calc(6);
		}
	}
}

.wptrigger_append {
	margin-top: bsu(1);
}

.wptgg_loading {
	background: url('../images/ajax.svg') no-repeat center !important;
	background-size: 50px !important;
	padding: 20px 40px !important;
}
