/**
 * Home page-specific wrappers and stuff
 *
 * @since   1.0.0
 * @package aaa
 */
.home {
	background: $gray-light;

	#content {
		width: 100%;
	}

	[role="main"] {
		padding: 0 0 bsu(1);
	}
}

.sidebars-main-wrap {
	width: 100%;

	aside.sidebar {
		margin-left: 0;
		padding: bsu(3/4) 0 0;

		> ul {
			display: flex;
			flex-flow: column-reverse nowrap;
		}

		.promo, .promo-user {
			border-radius: 0;
		}
	}
}

#sidebar-front-left {
	max-width: none;

	.widget {
		display: none;
	}
}

#sidebar-front-right {
	background: $white;
}

@include breakpoint( large ) {

	.home [role="main"] {
		flex: 1 1 auto;
		width: 100%;
	}

	.sidebars-main-wrap {
		@include flex-row;

		aside.sidebar {
			flex: 0 0 auto;

			> ul {
				flex-direction: column;
			}
		}
	}

	#sidebar-front-right {
		padding-top: 0;
		width: rem-calc(340);
	}

	.news-latest,
	#sidebar-front-left {
		padding-left: $widget-padding;
		padding-right: $widget-padding;
	}

} // bp: large

@include breakpoint( xlarge ) {

	.home [role="main"] {
		padding: bsu(4/5) 0 bsu(1);
	}

	.left-sidebar-main-wrap {
		display: flex;
	}

	#sidebar-front-left {
		padding: 0;
		width: rem-calc(300);
		background: $white;

		.widget {
			display: block;
		}
	}
}

@include breakpoint( xxlarge ) {

	#sidebar-front-left {
		width: rem-calc(320);
	}

	#sidebar-front-right {
		width: rem-calc(480);
	}

	.news-latest {
		padding-left: bsu(1);
		padding-right: bsu(1);
	}
}

#sidebar-front-left .widget-search {
	display: flex;
}
