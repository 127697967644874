/**
 * Related assets grid
 *
 * @since   1.0.0
 * @package aaa
 */
.related-assets .widgettitle {
	margin-bottom: bsu(1/2);

	@include breakpoint( medium ) {
		margin-bottom: bsu(3/4);
	}
}
