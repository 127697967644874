.people {
	margin-top: 40px;
}

.section-title + .entry-content {
	margin-top: 20px;
}

.users {
	display: flex;
	flex-wrap: wrap;
	margin: -40px -20px;
	padding: 20px 0;
	font-size: 0;
}

.user {
	width: 50%;
	padding: 40px 20px;
	font-size: 1rem;
	position: relative;

	@media (min-width: 900px) {
		display: flex;
		flex-flow: column;
		width: 33%;
		width: calc(100%/3);
	}

	&_image {

		a {
			display: inline-block;
			position: relative;

			&:before {
				content: '';
				display: block;
				width: calc(100% - 20px);
				height: calc(100% - 20px);
				background: $blue;
				position: absolute;
				left: 10px;
				top: 10px;
				opacity: 0;
				transition: opacity .25s;
				z-index: 1;
			}

			&:hover {
				span {
					opacity: 1;
				}

				&:before {
					opacity: .70;
				}
			}
		}

		span {
			width: 100%;
			padding: 0 40px;
			font-size: 15px;
			line-height: 24px;
			color: white;
			font-weight: bold;
			text-align: center;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			right: 0;
			opacity: 0;
			transition: opacity .25s;
			z-index: 1;

			svg {
				display: block;
				margin: 0 auto;
			}
		}

		img {
			display: block;
			border-radius: $border-radius;
		}
	}

	&_details {
		display: none;
	}

	&_title {
		color: #7a7a7a;
		font-family: $font-heading;
	}

	&_bio {
		margin-top: 20px;
		font-size: 14px;
		line-height: 2;

		.full {
			display: none;
		}

		.excerpt {
			display: none;
		}
	}

	a.user_bio-link {
		display: block;
		margin-top: 15px;
		font-size: 16px;
		line-height: 1.5;
		font-family: $font-heading;
		color: $blue;
		font-weight: 600;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}

	// Full width variant
	&.-full {
		width: 100%;
		padding-bottom: 100px;
		margin-bottom: 40px;
		position: relative;

		&:after {
			display: block;
			content: '';
			width: calc(100% - 40px);
			height: 1px;
			background: #D8D8D8;
			position: absolute;
			left: 20px;
			bottom: 0;
		}

		.user_content {
			display: flex;
		}

		.user_content-side {
			width: 200px;
			margin-right: 40px;
		}

		.user_content-main {
			width: calc(100% - 240px);
		}

		.user_bio {
			font-size: 18px;
			line-height: calc(5/3);
		}


		@media (min-width: 900px) {
			.user_content-side {
				// width: 33%;
				// width: calc(100%/3);
			}
		}
	}
}

// User Content Modal
.featherlight {
	&:last-of-type {
		background: rgba(24,24,24,.85);
	}

	.featherlight-content {
		max-width: 80%;
		padding: 20px 20px 0;
		border-bottom-width: 20px;
		overflow: visible;
	}

	.featherlight-close-icon {
		display: block;
		background: red;
		color: #fff;
		text-align: center;
		transform: translate(50%, -50%);
	}

	.user_content {
		.full {
			display: block;
		}

		.excerpt {
			display: none;
		}
	}
}

.user_content.featherlight-inner {
	display: flex;
	max-width: 900px;

	.user_content-side {
		max-width: 38%;
		margin-right: 2%;
	}

	.user_content-main {
		// width: calc(100% - 240px);
	}

	.user_details {

		&,
		dt,
		dd {
			display: block;
		}

		dt {
			width: 100px;
			font-weight: 600;
		}

		dd {
			margin-left: 0;
			margin-inline-start: 0;
		}
	}
}


// Test reorg of User modal
.featherlight-content {
	.user_content {
		display: block;
		max-height: 90vh;
		overflow-y: auto;
		overflow-x: hidden;
		max-width: 100%;
		width: 900px;
	}

	.user_content.featherlight-inner .user_content-side {
		display: block;
		width: 100%;
		max-width: 100%;
		margin: 0 auto;
		@include breakpoint(large) {
			display: flex;
			width: 100%;
			max-width: none;
		}
	}

	.user_image {
		width: 100%;
		margin: 0 auto;
		text-align: center;
		@include breakpoint(large) {
			width: 200px;
			margin-right: 40px;
		}
	}

	.user_details {
		width: 100%;
		margin: 0 auto;
		@include breakpoint(large) {
			width: calc(100% - 240px);
		}
	}
}

// Test reorg of User modal for About AAA page
.about-aaa {
	.featherlight-content {
		.user_content {
			display: flex;
		}

		.user_details {
			display: none;
		}
	}
}