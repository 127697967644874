/**
 * Promo
 *
 * @since   1.0.0
 * @package aaa
 */

// shared stuff
.promo, .promo-user {
	overflow: hidden;

	&, a {
		width: 100%;
	}

	&, figcaption {
		position: relative;
	}

	figcaption {
		z-index: 2;
		padding: bsu(1) 0;
		color: $white;
	}

	h3 {
		font-weight: 700;
		font-size: rem-calc(24);
	}

	p {
		line-height: $line-height-mid;
	}

	.logo-white-icon-only {
		flex: 0 0 auto;
		width: rem-calc(87);

		svg {
			height: rem-calc(52);
		}
	}

	@include breakpoint( large ) {
		border-radius: $border-radius;
	}
}


// regular promo
.promo {

	&, a {
		display: flex; // on both for IE min-height bug
	}

	a {
		min-height: rem-calc(330);

		&:before {
			@include overlay(1);
			background: linear-gradient( to top, rgba( $black, 0.9 ), trans( $black ) );
		}
	}

	p {
		font-family: $font-heading;
		margin-top: bsu(1/4);
		font-size: rem-calc(16);
	}

	[role="button"] {
		margin-top: bsu(2/3);
	}

	&.style-bottom-left {

		a {
			align-items: flex-end;
		}

		.logo-title-body-wrap {
			display: flex;
		}

		.logo-white-icon-only {
			margin: bsu(1/2) bsu(3/4) 0 0;
		}
	}

	&.style-bottom-left a {
		align-items: flex-end;
	}

	&.style-center {
		text-align: center;

		a {
			align-items: center;

			&:before {
				background: rgba( $black, 0.75 );
			}
		}

		p {
			line-height: $line-height-small;
			font-size: rem-calc(20);
			font-weight: 600;
		}

		.logo-white-icon-only {
			display: inline-block;
		}

	} // style-center

	@include breakpoint( large ) {

		figcaption {
			padding: bsu(1) $widget-padding;
		}
	}

	@include breakpoint( xxlarge ) {

		figcaption {
			padding: bsu(1) $widget-padding-large;
		}

		[role="button"] {
			margin-top: bsu(1);
		}

		&.style-center {

			a {
				min-height: rem-calc(400);
			}

			h3 {
				font-size: rem-calc(36);
			}

			p {
				font-size: rem-calc(24);
			}

		} // &.style-center
	}
}
