/**
 * Header
 *
 * @since   1.0.0
 * @package aaa
 */

header[role="banner"] {
	position: fixed;
	z-index: 50;
	top: 0;
	right: 0;
	left: 0;
	box-shadow: 0 2px 20px rgba( $black, 0.14 );

	&.nav-toggled .site-logo {
		opacity: 0;
	}

	> div {
		width: 100%;
	}

	.logo-toggle,
	.site-logo {
		@include flex-row;
	}

	// logo / mobile toggle wrap
	.logo-toggle {
		flex: 1 0 auto;
		position: relative;
		height: $header-height;
		background: $white;
	}

	// logo
	.site-logo {
		margin: 0 auto;
		align-items: center;
		margin-right: auto;

		.svg {
			width: rem-calc(175);
		}

		p {
			display: none;
		}
	}

	.svg {
		width: 160px;
	}


	// break from mobile to desktop navigation
	@include breakpoint( menu-break ) {
		top: $top-strip-height;
		height: $header-height-desk;
		background: $white;
		border-bottom: 10px solid $red;

		&, > div {
			@include flex-row;
		}

		> div {
			@include wrap;
		}

		.logo-toggle {
			flex: 0 1 auto;
			padding: 0;
			height: auto;
			background: none;
		}

		.site-logo .svg {
			width: rem-calc(200);
		}

	} // menu break


	@include breakpoint( xxlarge ) {

		.site-logo .svg {
			width: rem-calc(310);
		}
	}


	/**
	 * Admin bar fix - for "non-static" headers
	 */
	body.admin-bar & {
		@include breakpoint( 600px ) {
			top: 46px !important;
		}

		@include breakpoint( 782px ) {
			top: 32px + $top-strip-height !important;
		}
	}

} // header[role="banner"]



/**
 * Mobile navigation / search toggles
 */
header[role="banner"] .logo-toggle button,
form[role="search"] .close {
	width: $header-height - 8px;
	padding: 0;
	color: $black;
	background: none;
	border: none;
	border-radius: 0;
}


#menu-toggle,
#search-toggle,
header[role="banner"] .site-logo {
	@include transition-ease-in-out( $speed-slow );
}

#menu-toggle {
	position: relative;
	left: 0;
	z-index: 1;
	transform: translateX( 0% );
	transition-property: left, transform;

	.hamburger {
		@include hamburger( 19px, 3px, 3px, $black, 2px );
	}

	&.toggled .hamburger {
		@include hamburger-to-cross;
	}

	.nav-toggled & {
		left: 100%;
		transform: translateX( -100% );
	}

	@include breakpoint( menu-break ) {
		display: none;
	}

} // #menu-toggle

#search-toggle,
header[role="banner"] .site-logo {
	transition-property: opacity;
	opacity: 1;
}

#search-toggle,
form[role="search"] .close {
	@include flex-row( true );
	justify-content: center;

	@include breakpoint( menu-break ) {
		display: none;
	}
}

#search-toggle {
	font-size: rem-calc(18);

	.nav-toggled & {
		opacity: 0;
	}
}


/**
 * Opened navigation overlay
 */
.nav-overlay {
	@include overlay( -1 );
	display: none;
	position: fixed;
	cursor: pointer;
	background: rgba( $white, 0.5 );

	@include breakpoint( menu-break ) {
		display: none !important; // override fadeToggle
	}
}


/**
 * Menu/search panels
 */
header[role="banner"] > div > nav,
header[role="banner"] form[role="search"] {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	overflow: hidden;
	transition-property: transform, top, opacity;

	&.toggled {
		transform: translateX( 0% );
	}

	&.scroll-y {
		bottom: 100%;
		overflow-y: scroll;

		&.toggled {
			bottom: 0;
		}
	}

} // header nav, search

header[role="banner"] > div > nav {
	transform: translateX( -100% );
	@include transition-ease-in-out( 400ms );
}

header[role="banner"] form[role="search"] {
	transform: translateX( 100% );
	opacity: 0;
	@include transition-ease-in-out( 350ms );

	&.toggled {
		opacity: 1;
	}

	@include breakpoint( menu-break ) {
		position: relative;
		width: auto;
		height: auto;
		transform: none;
		transition-property: none;
		opacity: 1;
	}
}
