/**
 * Expertise widget
 *
 * @since   1.0.0
 * @package aaa
 */
.widget-expertise {

	.widgettitle {
		margin-bottom: bsu(3/4);
	}

	.entry-content {
		padding: bsu(3/4) bsu(1);
		border: 1px solid #b6b6b6;
		color: $gray-darker;

		+ .promo-user {
			margin-top: bsu(1.25);
		}
	}


	@include breakpoint( large ) {

		.promo-user {
			margin-bottom: bsu(1.5);
		}
	}
}
