/**
 * Helper functions
 *
 * @since   1.0.0
 * @package aaa
 */

/**
 * Base spacing unit
 */
@function bsu( $multiplier ) {
	@return $multiplier * $bsu;
}


/**
 * Create a "transparent" version of a color
 */
@function trans( $color : $white ) {
	@return rgba( $color, 0 );
}


/**
 * Rem Calc!
 */
$global-font-size: 100% !default;

@function strip-unit( $num ) {
	@return $num / ( $num * 0 + 1 );
}

@function rem-calc( $values, $base: null ) {

	$rem-values: ();
	$count: length( $values );

	// If no base is defined, defer to the global font size
	@if $base == null {
		$base: $global-font-size;
	}

	// If the base font size is a %, then multiply it by 16px
	// This is because 100% font size = 16px in most all browsers
	@if unit( $base ) == '%' {
		$base: ( $base / 100% ) * 16px;
	}

	@if $count == 1 {
		@return -zf-to-rem( $values, $base );
	}

	@for $i from 1 through $count {
		$rem-values: append( $rem-values, -zf-to-rem( nth( $values, $i ), $base ) );
	}

	@return $rem-values;
}

@function -zf-to-rem( $value, $base: null ) {

	// Check if the value is a number
	@if type-of( $value ) != 'number' {
		@warn inspect( $value ) + ' was passed to rem-calc(), which is not a number.';
		@return $value;
	}

	// Calculate rem if units for $value is not rem
	@if unit( $value ) != 'rem' {
		$value: strip-unit( $value ) / strip-unit( $base ) * 1rem;
	}

	// Turn 0rem into 0
	@if $value == 0rem {
		$value: 0;
	}

	@return $value;
}
