/**
 * Banner
 *
 * @since   1.0.0
 * @package aaa
 */

.banner {
	position: relative;
	display: flex;
	overflow: hidden;
	align-items: center;

	.wrap {
		position: relative;
	}

	// background image
	img {
		z-index: -1;
	}

	// home page adjustments (parent selector)
	body.home & {

	}
}
