/**
 * Navigation menu widget theming
 *
 * @since   1.0.0
 * @package aaa
 */
.widget_nav_menu {
	font-size: rem-calc(16);
	line-height: $line-height-small;

	.widgettitle {
		margin-bottom: bsu(1/3);
	}

	a {
		display: block;
		padding: bsu(1/4) 0;
		color: $gray-dark;
		@include transition-color( $blue );
	}

	.menu-item {
		position: relative;
	}

	.menu-item-has-children > a {
		padding-right: rem-calc(40);
	}

	.sub-menu {
		display: none;
		padding-bottom: bsu(1/4);

		a {
			font-size: rem-calc(14);
			font-weight: 600;
		}
	}

	.toggle-sub {
		position: absolute;
		top: bsu(1/5);
		right: 0;
		padding: rem-calc(5) rem-calc(8);
		color: $white;
		background: $gray;
		border: none;
		border-radius: $border-radius;

		&:hover, &:focus {
			background-color: $blue;
		}
	}

	.sub-toggled .toggle-sub {
		background: $gray;
		transform: rotate( 180deg );
	}
}
