/**
 * Article card
 *
 * @since   1.0.0
 * @package aaa
 */
.card {
	box-shadow: $card-shadow;
	border-radius: $border-radius;
	overflow: hidden;
	min-height: rem-calc(80);
	transition-property: transform, box-shadow;
	@include transition-ease-in-out( 340ms );

	&:hover {
		transform: translateY( -4px );
		box-shadow: $card-shadow-hover;
	}

	&, a {
		@include flex-row;
		width: 100%;
	}

	.image-wrap,
	figcaption {
		position: relative;
	}

	.image-wrap {
		flex: 0 0 auto;
		width: rem-calc(80);
		background: $gray;
	}

	figcaption {
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
		width: 100%;
		padding: bsu(2/5) bsu(1/2);
		background: $white;

		.category-background {
			display: none;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			width: 6px;
		}
	}

	.term-labels, time,
	h2 {
		font-size: rem-calc(14);
		line-height: 1.25;
	}

	.term-labels, time {
		margin-bottom: bsu(1/5);
	}

	.term-labels {
		@include no-font-smoothing;
	}

	time {
		margin-left: auto;
		color: $gray-dark;
	}

	h2 {
		width: 100%;
	}


	/**
	 * Breakpoints
	 */
	@include breakpoint( medium ) {

		a {
			flex-flow: column nowrap;
		}

		.image-wrap {
			width: 100%;
			height: rem-calc(150);
		}

		figcaption {
			flex: 1 0 auto; // grow to bottom
			padding: bsu(3/4) bsu(1);

			.category-background {
				display: block;
			}
		}

		.term-labels, time {
			margin-bottom: bsu(1/4);
		}

		h2 {
			font-size: rem-calc(18);
			font-weight: 700;
		}

	} // bp: medium

	@include breakpoint( xlarge ) {

		.image-wrap {
			height: rem-calc(200);
		}

		figcaption {
			padding: bsu(1) bsu(1.25) bsu(1.5);

			.category-background {
				width: 10px;
			}
		}

		h2 {
			font-size: rem-calc(20);
			line-height: $line-height-mid;
		}
	}
}
