/**
 * Article or asset "tile"
 *
 * @todo    break this into partials
 *
 * @since   1.0.0
 * @package aaa
 */
.asset-tile {
	width: 100%;

	.image-wrap, figcaption, .term-labels, button {
		position: relative;
	}

	.image-wrap,
	img {
		display: block;
	}

	// transitions,
	.term-labels,
	.image-wrap:before,
	h3 {
		@include transition-ease-in-out( 300ms );
	}

	.term-labels,
	.image-wrap:before {
		transition-property: opacity;
		opacity: 0;
	}

	h3 {
		font-family: $font-sans-serif;
		font-weight: 600;
		font-size: rem-calc(14);
		line-height: $line-height-small;
		transition-property: opacity, color;
	}

	// hover
	a:hover {

		.term-labels,
		&.image-wrap:before,
		.image-wrap:before {
			opacity: 1;
		}

		[role="button"].play {
			@include button-play-hover;
		}
	}

}  // .asset-tile


// shared video / image
.asset-tile-video,
.asset-tile-image {

	.image-wrap {
		height: rem-calc(145);
		border-radius: $border-radius;
		overflow: hidden;

		&:before {
			@include overlay;
			background: rgba( $black, 0.7 );
		}
	}

	img {
		width: 100%;

		&[data-object-fit] {
			z-index: -1; // go below icons/text/overlay
		}
	}

	.term-labels {
		position: absolute;
	}

	@include breakpoint( xxlarge ) {

		.image-wrap {
			height: rem-calc(150);
		}
	}
}


// video
.asset-tile-video {

	.image-wrap {
		margin-bottom: bsu(1/3);
	}

	.term-labels {
		top: bsu(1/4);
		left: bsu(1/4);
	}

	h3:hover {
		color: $blue;
	}

	@include breakpoint( medium ) {

		.term-labels {
			top: bsu(1/2);
			left: bsu(1/2);
		}
	}

} // .asset-tile-video


// image
.asset-tile-image {

	.term-labels {
		top: bsu(1/2);
		left: bsu(1/2);
	}

	figcaption {
		position: absolute;
		bottom: 0;
		left: 0;
		padding: bsu(1/3) bsu(1/2);
		width: 100%;
		color: $white;
	}

	h3 {
		opacity: 0;
	}

	a:hover h3 {
		opacity: 1;
	}

	@include breakpoint( medium ) {

		.term-labels {
			top: bsu(2/3);
			left: bsu(2/3);
		}

		figcaption {
			padding: bsu(4/5) bsu(2/3);
		}
	}

	@include breakpoint( large ) {

		[role="main"] & .image-wrap {
			height: rem-calc(200);
		}
	}

} // .asset-tile-image


// link
.asset-tile-link {
	background: $gray-light;
	border-radius: $border-radius;
	font-size: rem-calc(14);
	overflow: hidden;

	.image-wrap {
		height: rem-calc(150);
	}

	figcaption {
		padding: bsu(4/5) bsu(2/3);
	}

	h3 {
		color: $blue;
	}

	p {
		margin-top: bsu(1/3);
	}
}


// file
.asset-tile-file {
	display: flex;
	background: $gray-light;
	border-radius: $border-radius;
	overflow: hidden;
	transition-property: background-color;

	&:after {
		@include overlay;
		left: -5%;
		background: linear-gradient( to right, trans( $blue ) 10%, $blue 80% );
		opacity: 0;
		transition-property: opacity;
		pointer-events: none;
	}

	&:hover {
		background-color: $blue;

		&:after,
		[role="button"] .text {
			opacity: 1;
		}

		h3,
		.file-permalink .svg-inline--fa,
		[role="button"] {
			color: $white;
		}
	}

	&, &:after,
	.file-permalink, .file-permalink .svg-inline--fa,
	h3,
	[role="button"], [role="button"] .text {
		@include transition-ease-in-out( 250ms );
	}

	&, .file-permalink {
		position: relative;
	}

	.file-permalink .svg-inline--fa,
	[role="button"] {
		position: absolute;
	}

	.file-permalink .svg-inline--fa,
	h3,
	[role="button"] {
		transition-property: color;
	}

	.file-permalink {
		@include flex-row( true );
		position: relative;
		padding: bsu(1/4) rem-calc(40) bsu(1/4) rem-calc(36);
		min-height: rem-calc(55);

		.svg-inline--fa {
			top: 50%;
			left: bsu(1/2.5);
			transform: translateY( -50% );
			color: $red;
		}
	}

	[role="button"] {
		z-index: 1;
		right: 0;
		top: 0;
		bottom: 0;
		padding: bsu(1/3);
		background: none;
		border-color: transparent;
		border-radius: 0;
		font-weight: 600;
		color: $gray-dark;

		.text {
			opacity: 0;
			transition-property: opacity;
		}

		&:hover {
			color: #95d5ff;
		}
	}


	@include breakpoint( medium ) {

		.file-permalink {
			padding: bsu(1/2) rem-calc(78) bsu(1/2) rem-calc(52);
			min-height: rem-calc(65);

			.svg-inline--fa {
				left: bsu(2/3);
				font-size: rem-calc(21);
			}
		}

		[role="button"] {
			padding-right: rem-calc(30);

			.svg-inline--fa {
				font-size: rem-calc(16);
			}
		}
	}

} // .asset-tile-file

