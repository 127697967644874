.locations {
	display: flex;
	margin: 40px -15px -10px;
}

.location {
	width: 50%;
	padding: 15px;
	font-size: 14px;

	&, .image-wrap {
		position: relative;
	}

	.image-wrap {
		height: rem-calc(280);
	}

	&_title {
		margin-top: 10px;
		font-size: 16px;
		line-height: 24px;
	}

	p {
		margin-top: 10px;
	}
}