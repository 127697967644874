/**
 * Form
 *
 * @since   1.0.0
 * @package aaa
 */

form {

	ul {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.buttons {
		@include flex-row;
		justify-self: flex-end;
		margin-top: bsu(1/3);

		[type="submit"] {
			margin-top: 0;
		}

		.reset {
			margin-left: auto;
		}
	}

	@include breakpoint( large ) {

		.buttons .reset {
			margin-left: bsu(1/4);
		}
	}

} // form

input, textarea, select, label {
	display: block;
	width: 100%;
	font-size: rem-calc(14);
	line-height: $line-height-mid;

	&:focus {
		outline: none;
	}
}

input, textarea, select {
	margin-bottom: bsu(2/3);
	padding: rem-calc(13) rem-calc(18) rem-calc(12);
	background: $gray-lighter;
	border: 1px solid $gray;
	border-radius: $border-radius;
	transition-property: border-color;
	@include transition-ease-in-out;

	&:focus {
		border-color: $gray-dark;
	}

	&.error {
		border-color: $red;
	}

	@include breakpoint( medium ) {
		margin-bottom: bsu(3/4);
	}
}

textarea {
	resize: vertical;
	height: rem-calc(180);
}

[type="search"] {
	-webkit-appearance: textfield;
}

select {
	appearance: none;
}

select {
	padding-right: rem-calc(52);
	background-image: url( '../images/caret-down.svg' );
	background-repeat: no-repeat;
	background-position: calc( 100% - 22px ) 50%;
	background-size: rem-calc(9);

	&::-ms-expand {
		display: none;
	}
}

label {
	margin-bottom: bsu(1/4);
	padding-left: rem-calc(18);
	font-family: $font-heading;
	font-weight: 600;
}


/**
 * Checkboxes/radios
 */
[type="checkbox"],
[type="radio"] {
	position: absolute;
	width: 4px;
	height: 4px;
	opacity: 0;
}

[type="checkbox"] + label,
[type="radio"] + label,
.woocommerce-form__label-for-checkbox {
	position: relative;
	margin: 0 0 0 rem-calc(18);
	padding: rem-calc(6) 0 rem-calc(6) rem-calc(35);
	width: auto;
	font-weight: 400;
	font-family: $font-sans-serif;
	cursor: pointer;
	transition-property: color;

	// .gfield_checkbox &,
	// .gfield_radio & {
	// 	margin-left: rem-calc(8);
	// }

	&, &:before, &:after {
		@include transition-ease-in-out;
	}

	&:before, &:after {
		content: '';
		position: absolute;
	}

	&:before {
		top: rem-calc(5);
		left: 0;
		margin-right: rem-calc(14);
		width: 20px;
		height: 20px;
		box-shadow: inset 0 0 0 1px $gray;
		border-radius: $border-radius;
		transition-property: box-shadow, background-color;
	}

	&:after {
		top: rem-calc(7);
		left: rem-calc(3);
		width: 14px;
		height: 15px;
		background: url( '../images/check.svg' ) no-repeat center;
		background-size: contain;
		opacity: 0;
		transition-property: opacity;
	}

	&:hover {
		color: $blue;
	}

} // checkbox and radio labels

// focus
[type="checkbox"]:focus + label:before,
[type="radio"]:focus + label:before {
	box-shadow: inset 0 0 0 1px $blue;
}

// checked
[type="radio"]:checked + label:before,
.woocommerce-form__label-for-checkbox.input-checked:before {
	box-shadow: inset 0 0 0 7px $blue;
}

[type="checkbox"]:checked + label {

	&:before {
		box-shadow: none;
		background-color: $blue;
	}
	&:after {
		opacity: 1;
	}
}

// radios
[type="radio"] + label:before,
[type="radio"] + label:after {
	border-radius: 9999px;
}

.fields > li ul, ul.gfield_checkbox,
.fields > li ul, ul.gfield_radio {
	margin: bsu(1/4) 0 bsu(2/3);
}

// datepicker field
.ginput_container_date {
	position: relative;

	.svg-inline--fa {
		position: absolute;
		top: 50%;
		right: rem-calc(20);
		font-size: rem-calc(20);
		transform: translateY( -50% );
		color: $gray;
	}
}

// submit
[type="submit"] {
	margin-top: bsu(1/3);
}


/**
 * Validation
 */
.validation_error,
.validation_message,
.validation-message {
	color: $red;
	font-size: rem-calc(14);
	font-family: $font-heading;
	font-weight: 700;
}

.validation_error {
	margin-bottom: bsu(1);
	padding: rem-calc(15) rem-calc(20);
	text-align: center;
	border: $border-width solid $red;
}

.validation_message,
.validation-message {
	margin-top: bsu(-1/3);
	margin-bottom: bsu(3/4);
	padding-left: rem-calc(13);

	.gfield_checkbox &,
	.gfield_radio & {
		padding-left: rem-calc(12);
	}

	.svg-inline--fa {
		margin-right: rem-calc(7);
	}

	@include breakpoint( medium ) {
		margin-top: bsu(-1/2);
	}
}

.fields > li.error, .gfield_error {

	input, textarea, select {
		border-color: $red;
	}
}


/**
 * Layout
 */
.fields, .gform_fields, form.mailpoet_form {
	font-size: 0;
	margin: 0 bsu(-2/5);
}

.fields > li, .gfield, .mailpoet_paragraph {
	position: relative;
	display: inline-block;
	vertical-align: top;
	padding: 0 bsu(2/5);
	width: 100%;

	@include breakpoint( small ) {

		&.one-half-small {
			width: 50%;
		}
	}

	@include breakpoint( medium ) {

		&.one-half {
			width: 50%;
		}
	}
}
