/**
 * Search form
 *
 * @since   1.0.0
 * @package aaa
 */
[role="banner"] form[role="search"] {
	display: flex;
	flex-flow: column nowrap; // row wrap;
	align-items: center;
	align-content: flex-start;
	z-index: 2;
	width: 100%;
	background: $white;

	.close {
		margin-left: calc( 100% - #{$header-height} + 8px );
		height: $header-height;
		font-size: rem-calc(20);

		.text {
			display: none;
		}
	}

	.close .icon,
	label,
	input, select {
		margin: 0;
	}

	// background thing
	> .fa-search {
		@include absolute-center;
		width: 45%;
		pointer-events: none;
		opacity: 0.05;
	}

	.wrap {
		font-size: 0;
	}

	label {
		display: inline-block;
		position: relative;
		padding: 0;
		border-bottom: 1px solid $gray;

		&:first-child select,
		&:first-child input {
			padding-left: 0;
		}
	}

	[for="s"] {
		width: 60%;

		&:before {
			content: '';
			position: absolute;
			top: rem-calc(7);
			bottom: rem-calc(9);
			left: 0;
			width: 1px;
			background: $gray;
		}
	}

	[for$="asset_type"] {
		width: 40%;
	}

	input, select {
		padding: rem-calc(14);
		border: none;
		background-color: transparent;
		font-family: $font-sans-serif;
		font-weight: 400;
	}

	select {
		padding-right: rem-calc(32);
		background-position: calc( 100% - 14px ) 50%;
	}

	.advanced {
		margin: bsu(1/2) 0;
		align-self: flex-end;
		margin-right: ( 100% - $wrap-width ) / 2;
		font-size: rem-calc(12);
		line-height: $line-height-small;
		@include inline-link( $blue-light );
		@include no-font-smoothing;
	}

	[type="submit"] {
		margin-top: auto;
		margin-bottom: bsu(1);
	}


	@include breakpoint( menu-break ) {
		align-self: center;
		flex-flow: row nowrap;
		margin: 0 0 0 bsu(3/4);
		background: $gray-lighter;
		border-radius: 9999px;
		border: 1px solid $gray;

		.wrap {
			display: flex;
		}

		.advanced,
		> .fa-search,
		[type="submit"] .text {
			display: none;
		}

		label {
			border-bottom: none;

			&:first-child select,
			&:first-child input {
				padding-left: rem-calc(16);
			}
		}

		input, select {
			padding-top: rem-calc(12);
			padding-bottom: rem-calc(12);
		}

		[for$="asset_type"] {
			width: rem-calc(134);
		}

		[for="s"] {
			width: auto;
		}

		[type="submit"] {
			padding: bsu(1/4) rem-calc(18) bsu(1/4) bsu(1/4);
			font-size: rem-calc(18);
			color: $black;
			background: none;
			border: none;

			&, .icon {
				margin: 0;
			}

			&:hover, &:focus {
				background: none;
			}
		}

	} // bp: menu-break
}


.widget_search form[role="search"] {

	.close {
		display: none;
	}
}


/**
 * Full search
 */
.full-search {
	display: flex;
	flex-flow: row wrap;
	margin-top: bsu(1/2);
	border-radius: $border-radius;

	form {
		display: none;
		width: 100%;
	}

	[type="submit"] {
		padding-left: rem-calc(31);
		padding-right: rem-calc(31);
	}

	@include breakpoint( small ) {

		.field {
			width: 50%;
		}
	}

	@include breakpoint( medium ) {
		margin-top: bsu(1.25);
		padding: bsu(1);
		border: 1px solid $gray-mid-light;

		form {
			display: block !important; // override jQuery slideToggle
		}
	}

	@include breakpoint( large ) {
		margin-top: bsu(2.25);

		form {
			display: flex !important;
			flex-flow: row wrap;
		}

		.fields {
			flex: 1 1 auto;
			width: 100%; // for IE
		}

		.buttons {
			justify-content: flex-end;
		}

		.asset-type,
		.category,
		.orderby {
			width: 25%;
		}

		.search {
			width: 25%;
		}
	}


	@include breakpoint( xlarge ) {
		padding: bsu(1) bsu(1.375) bsu(1.125) bsu(1.125);

		form {
			flex-wrap: nowrap;
			align-items: flex-end;
		}

		.fields {
			margin-right: auto;
			max-width: rem-calc(1020);
		}

		.asset-type,
		.category,
		.orderby {
			width: 21.5%;
		}

		.search {
			width: 35.5%;
		}

		label {
			padding-left: 0;
		}

		input, select {
			margin-bottom: 0;
		}

		.buttons {
			flex: 0 0 auto;
			margin: 0 0 0 bsu(1/2);
		}

	} // bp: xlarge
} // .full-search


/**
 * Field toggler
 */
#search-fields-toggle {
	margin-left: auto;
	text-align: right;

	@include breakpoint( medium ) {
		display: none;
	}
}
