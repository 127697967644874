/**
 * Default WordPress Stuff
 *
 * @since   1.0.0
 * @package aaa
 */

/*
	CLEARINGS
*/
.clear,
.entry-content,
.comment-content,
.site-header,
.site-footer {
	@include clearfix;
}


/*
	ALIGNMENTS
*/
.aligncenter {
	clear: both;
	@include center-block;
}

.alignnone {
    margin: bsu(2/3) 0;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: bsu(2/3) auto;
}


.alignright,
.alignleft {
	margin: bsu(2/3) 0;
}

@include breakpoint( medium ) {

	.alignright,
	.alignleft {
		display: inline;
		max-width: 60%;
	}

	.alignright {
		float: right;
	    margin-left: bsu(1);
	}

	.alignleft {
		float: left;
	    margin-right: bsu(1);
	}
}

.wp-caption {
	.wp-caption-text {
		margin-top: 0;
		padding-left: 20px;
		color: #d3d3d3;
		position: relative;

		&:before {
			content: '';
			width: 6px;
			height: 40px;
			max-height: 100%;
			position: absolute;
			left: 0;
			top: 9px;
			background: #00539B;
		}
	}
}

/*
	CAPTIONS / MEDIA
*/
.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;

	img[class*="wp-image-"] {
		display: block;
		width: 100%;
	}

	.wp-caption-text {
		margin: 0.40375em 0;
	}
}

.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}


/*
	SCREEN READERS
*/
.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;

	&:focus {
		background-color: $white;
		border-radius: 3px;
		box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
		clip: auto !important;
		color: $black;
		display: block;
		font-weight: bold;
		height: auto;
		left: 5px;
		line-height: normal;
		padding: 15px 23px 14px;
		text-decoration: none;
		top: 5px;
		width: auto;
		z-index: 100000; /* Above WP toolbar. */
	}
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}


/*
	JETPACK
*/

/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation, /* Older / Newer Posts Navigation (always hidden) */
.infinite-scroll.neverending .site-footer { /* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
	display: block;
}
