/**
 * SHARED widget styling
 *
 * @since   1.0.0
 * @package aaa
 */
$widget-padding       : bsu(3/4);
$widget-padding-large : bsu(1.125);

.widget {
	margin-bottom: bsu(1);
}

.widgettitle {
	@include h3-size;
}

// add wrap compat to some default widgets
.widget_nav_menu,
.widget_mailpoet_form {
	@include wrap;

	@include breakpoint( large ) {
		width: 100%;
	}
}

// default desktop side spacing
.sidebars-main-wrap {

	.widget-related-posts,
	.widget_nav_menu,
	.widget_search,
	.widget_mailpoet_form {

		@include breakpoint( large ) {
			padding-left: $widget-padding;
			padding-right: $widget-padding;
		}

		@include breakpoint( xxlarge ) {
			padding-left: $widget-padding-large;
			padding-right: $widget-padding-large;
		}
	}
}

[role="main"] .widget-area {
	margin-top: bsu(1);

	// main will normally be wrapped in this case
	.widget.wrap {
		width: 100%;
	}

	@include breakpoint( large ) {
		margin-top: bsu(1.333);
	}
}
