/**
 * Shared grid tooling
 *
 * @since   1.0.0
 * @package aaa
 */
$gutter         : bsu(1/3);
$gutter-medium  : bsu(1/2);
$gutter-large   : bsu(2/3);
$gutter-xlarge  : bsu(1);
$gutter-xxlarge : bsu(4/3);

$grid-sizes         : ( tiny, medium, large, xlarge, xxlarge, xxxlarge );
$grid-columns       : ( 2, 3, 4, 5 );
$gutter-breakpoints : (
	tiny     : $gutter,
	medium   : $gutter-medium,
	xxxlarge : $gutter-large,
);

.grid {
	flex-flow: row wrap;

	&, > * {
		display: flex;
	}

	> * {
		flex-basis: auto;
		width: 100%;
	}

	&, &.grid-narrow {
		@include grid-step( $gutter );
	}

	@each $grid-size in $grid-sizes {
		@include breakpoint( $grid-size ) {

			@each $grid-column in $grid-columns {
				&.#{$grid-size}-#{$grid-column} > * {
					width: percentage( 1 / $grid-column );
				}
			}
		}
	}

	@each $gutter-breakpoint, $gutter-width in $gutter-breakpoints {
		@include breakpoint( $gutter-breakpoint ) {

			@include grid-step( $gutter-width );

			@if $gutter-breakpoint == xxxlarge {

				&.grid-wide {
					@include grid-step( $gutter-xlarge );
				}

				&.grid-wider {
					@include grid-step( $gutter-xxlarge );
				}
			}
		}
	}
}


// special cases
.grid article,
.grid.asset-link > li {
	display: flex;
}
