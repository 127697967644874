/**
 * Mixins
 *
 * @since   1.0.0
 * @package aaa
 */

/**
 * Generic wrapper
 */
@mixin wrap( $max-width : $wrap-max, $width : $wrap-width ) {
	margin-left: auto;
	margin-right: auto;
	width: $width;
	max-width: $max-width;
}


/**
 * Object-fit "cover"
 */
@mixin cover-image {
	object-fit: cover;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}


/**
 * Centered block
 */
@mixin center-block {
	margin-left: auto;
	margin-right: auto;
}


/**
 * Centered element trick!
 */
@mixin absolute-center( $stretch: false, $extra-transforms: "", $top: 50%, $left: 50% ) {
	position: absolute;
	top: $top;
	left: $left;
	width: auto;
	max-width: 1000%;
	height: auto;
	transform: translateX( -50% ) translateY( -50% ) unquote( $extra-transforms );

	@if $stretch {
		min-width: 100%;
		min-height: 100%;
	}
}


/**
 * Make a flex "row"
 */
@mixin flex-row( $align-center: false, $inline-flex: false ) {

	@if $inline-flex {
		display: inline-flex;
	} @else {
		display: flex;
	}

	flex-flow: row nowrap;

	@if $align-center {
		align-items: center;
	}
}


/**
 * Detail (<small>)
 */
@mixin detail {
	font-size: rem-calc(14);
	@include no-font-smoothing;
}


/**
 * Font smoothing
 */
@mixin font-smoothing {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


/**
 * Kill font smoothing!
 */
@mixin no-font-smoothing {
	-webkit-font-smoothing: auto;
	-moz-osx-font-smoothing: auto;
}


/**
 * Inline text link
 */
@mixin inline-link( $color: $blue, $color-hover: $blue-lighter ) {
	color: $color;
	border-bottom: 1px solid $color;
	transition-property: color, border-bottom-color;
	@include transition-ease-in-out;

	&:hover {
		color: $color-hover;
		border-bottom-color: $color-hover;
	}
}


/**
 * Styled list
 */
@mixin list {
	font-size: rem-calc(14);

	li {
		position: relative;
		margin: bsu(1/3) 0;
		padding: bsu(1/3) bsu(2);

		// counter / icons
		&:before, &:after,
		.svg-inline--fa {
			position: absolute;
			left: 0;
			font-weight: 700;
			font-size: rem-calc(8);
			color: $blue;
		}
	}

	// nested
	ul, ol {
		margin-top: 0;
	}

	a {
		@include inline-link;
	}

	@include breakpoint( medium ) {
		font-size: $global-font-size;
	}
}


/**
 * Bulleted list
 *
 * Icons inserted with js/foot/icons.js
 */
@mixin bulleted-list {

	li {
		&:before {
			content: '';
			display: block;
			width: 8px;
			height: 8px;
			border-radius: 50%;
			background: $blue;
			top: rem-calc(20);
			left: rem-calc(35);

			@include breakpoint( medium ) {
				top: rem-calc(24);
			}
		}
	}
}


/**
 * Numbered list
 */
@mixin numbered-list {

	&, ol {
		counter-reset: section;
	}

	li:before {
		counter-increment: section;
		content: counters( section, "." ) ".";
		font-size: 1rem;
		top: rem-calc(7);
		left: rem-calc(25);

		@include breakpoint( medium ) {
			top: rem-calc(10);
		}
	}

	// nested
	ol li:before {
		font-size: 0.9rem;
	}
}


/**
 * Highlighted list
 */
@mixin highlighted-list {
	font-size: rem-calc(14);

	> li {
		padding: bsu(2/3) bsu(2/3) bsu(2/3) bsu(1.5);
		border-radius: $border-radius;
		border-left: 10px solid $blue;
		position: relative;
		// padding-left: bsu(3/4);
		margin: bsu(1/2.5) 0;
		line-height: $line-height-mid;
		background: $gray-light;
	}

	ul li .svg-inline--fa {
		top: rem-calc(3);
		left: 0;
	}

	@include breakpoint( medium ) {
		font-size: rem-calc(16);

		li .svg-inline--fa {
			top: rem-calc(30);
			left: bsu(1);
		}

		> li {
			padding: bsu(1) bsu(1.5) bsu(8/9) bsu(1.8);
		}

		ul li {
			font-size: rem-calc(14);
		}
	}

} // highlighted-list()


/**
 * Remove bottom margin if last child
 */
@mixin no-bottom-if-last {

	&:last-child {
		margin-bottom: 0;
	}
}


/**
 * Remove top margin if first child
 */
@mixin no-top-if-first {

&:first-child {
		margin-top: 0;
	}
}


/**
 * WYSIWYG content format
 */
@mixin wysiwyg {

	// base spacing
	h2, h2, h3, h4, h5, h6,
	> ul, > ol,
	form,
	blockquote {
		margin-top: bsu(1);
	}

	[role="button"] {
		margin: bsu(1) bsu(1/2) 0 0;
	}

	// no space if at the beginning or end
	h1, > h2, > h3, > h4, > h5, > h6,
	> p,
	> ol, > ul,
	blockquote, blockquote p {
		@include no-bottom-if-last;
		@include no-top-if-first;
	}


	// lists
	> ul, > ol {
		@include list;
	}
	> ul {
		@include bulleted-list;
		// li {
		// 	background: #F4F4F4;

		// 	&:before {
		// 		content: '';
		// 		display: block;
		// 		border-radius: 4px 0 0 4px;
		// 		left: 0;
		// 		top: 0;
		// 		width: 10px;
		// 		height: 100%;
		// 		background: $blue;
		// 		color: $black;
		// 	}
		// }
	}
	> ol {
		@include numbered-list;
	}

	p + h1,
	p + h2,
	p + h3,
	p + h4,
	p + h5,
	p + h6 {
		clear: both;
	}

} // wysiwyg


/**
 * Responsive embed container
 */
@mixin responsive-embed-container {
	position: relative;
	padding-bottom: 56.25%;
	width: 100%;
	max-width: 100%;
	height: auto;
	max-height: 440px;
	overflow: hidden;

	iframe, object, embed {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}


/**
 * Fluid Property!
 */
@mixin fp( $property, $min, $max, $start: 375, $end: 1280, $clip: true, $clipAtStart: true, $clipAtEnd: true ) {

	$multiplier: ($max - $min) / ($end - $start) * 100;
	$adder: ($min * $end - $max * $start) / ($end - $start);
	$formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});

	@if $clip and $clipAtStart {
		@media (max-width: #{$start + 0px}) {
			#{$property}: $min + 0px;
		}
	}
	@if $clip and $clipAtEnd {
		@media (min-width: #{$end + 0px}) {
			#{$property}: $max + 0px;
		}
	}

	#{$property}: $formula;

} // fp()

@function fp-calc( $min, $max, $start: 320, $end: 1280 ) {

	$multiplier: ($max - $min) / ($end - $start) * 100;
	$adder: ($min * $end - $max * $start) / ($end - $start);
	$formula: calc(#{$multiplier + 0vw} + #{$adder + 0px});

	@return $formula;
}


/**
 * Overlay
 */
@mixin overlay( $z-index: 0, $top: 0, $right: 0, $bottom: 0, $left: 0 ) {
	content: '';
	display: block;
	position: absolute;
	z-index: $z-index;
	top: $top;
	right: $right;
	bottom: $bottom;
	left: $left;
}


/**
 * Generic transition
 */
@mixin transition-ease-in-out( $speed: $speed ) {
	transition-timing-function: ease-in-out;
	transition-duration: $speed;
}


/**
 * Transition text color
 */
@mixin transition-color( $hover: false, $speed: 200ms ) {
	@include transition-ease-in-out( $speed );
	transition-property: color;

	@if $hover {
		&:hover {
			color: $hover;
		}
	}
}


/**
 * Calculate the wrapper + item offset for a grid size
 *
 * Call this on the grid wrapper
 *
 * @param number  $step-gutter  gutter/spacer width
 */
@mixin grid-step( $step-gutter ) {
	margin-left: -$step-gutter / 2;
	margin-right: -$step-gutter / 2;

	> * {
		margin-bottom: $step-gutter;
		padding-left: $step-gutter / 2;
		padding-right: $step-gutter / 2;
	}
}


/**
 * Clearfix
 */
@mixin clearfix {

	&:before {
		content: " ";
		display: table;
	}

	&:after {
		clear: both;
		content: " ";
		display: table;
	}
} // clearfix()


/**
 * Transforming Hamburger
 */
@mixin hamburger( $width: 20px, $height: 2px, $gutter: 4px, $color: $primary, $border-radius: 0, $transition-duration: .3s ) {

    $burger-height: $height !global;
    $burger-gutter: $gutter !global;

	position: relative;
	margin-left: auto;
	margin-right: auto;
    margin-top: $height + $gutter;
    margin-bottom: $height + $gutter;
    user-select: none;

    // 1. Fixes jagged edges in Firefox, see issue #10.
    &, &::before, &::after {
        display: block;
        width: $width;
        height: $height;
		background-color: $color;
		border-radius: 2px;
        outline: 1px solid transparent; // 1
        @if $border-radius != 0 {
            border-radius: $border-radius;
        }
        transition-property: background-color, transform;
        transition-duration: $transition-duration;
    }

    &::before, &::after {
        position: absolute;
        content: "";
    }

    &::before {
        top: -($height + $gutter);
    }

    &::after {
        top: $height + $gutter;
    }
}


// Select parts of the burger
@mixin burger-parts {
    &, &::before, &::after {
        @content;
    }
}

@mixin burger-top {
    &::before {
        @content;
    }
}

@mixin burger-middle {
    & {
        @content;
    }
}

@mixin burger-bottom {
    &::after {
        @content;
    }
}


// animate to cross
@mixin hamburger-to-cross( $color: auto ) {
    & {
        background-color: transparent;
    }
    @if ($color != auto) {
        &::before, &::after {
            background-color: $color;
        }
    }
    &::before {
        transform: translateY( $burger-gutter + $burger-height ) rotate( 45deg );
    }
    &::after {
        transform: translateY( -( $burger-gutter + $burger-height ) ) rotate( -45deg );
    }
}
