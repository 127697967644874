/**
 * Latest news listing
 *
 * @since   1.0.0
 * @package aaa
 */
.news-latest {

	.h2 {
		margin-bottom: bsu(1/2);
	}

	.widget {
		margin-bottom: $gutter;
	}

	.view-all-wrap {
		text-align: center;

		.view-all-button {
			margin-top: bsu(3/4);
		}
	}

	@include breakpoint( medium ) {

		.widget {
			margin-bottom: $gutter-medium;
		}
	}

	@include breakpoint( large ) {
		padding-bottom: bsu(1/3); // side padding in home.scss

		.card figcaption {
			padding-bottom: bsu(1);
		}
	}

	@include breakpoint( xxlarge ) {
		padding-bottom: bsu(1.125); // side padding in home.scss
	}

	@include breakpoint( xxxlarge ) {

		.widget {
			margin-bottom: $gutter-xlarge;
		}
	}
}
