/**
 * Typography
 *
 * @since   1.0.0
 * @package aaa
 */

*::selection {
	background: rgba( $blue, 0.9 );
	color: $white;
	text-shadow: none;
}
*::-moz-selection {
	background: rgba( $blue, 0.9 );
	color: $white;
	text-shadow: none;
}

html, body {
	font-size: $global-font-size;
}

body {
	line-height: $line-height-body;
	color: $black;
	font-family: $font-sans-serif;
	font-weight: 300;
	@include font-smoothing;
}


/**
 * Headings
 */

@mixin h3-size {
	font-size: rem-calc(20);

	@include breakpoint( medium ) {
		font-size: rem-calc(24);
	}
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	margin: 0;
	line-height: $line-height-mid;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5 {
	font-family: $font-heading;
}

h1, .h1, h4, .h4 {
	font-weight: 700;
}

h2, .h2, h3, .h3, h5, h6 {
	font-weight: 600;
}

h1, .h1 {
	@include fp( font-size, 24, 36 );

	strong {
		color: $blue;
	}

	&.entry-title {
		line-height: 1.3611;
	}
}

h2, .h2 {
	font-size: rem-calc(24);
}

h3, .h3 {
	@include h3-size;
}

h4, .h4 {
	font-size: rem-calc(17);
}

h5, .h5 {
	font-size: rem-calc(16);
}

h6 {
	font-size: rem-calc(14);
}

@include breakpoint( medium ) {

	h2, .h2 {
		font-size: rem-calc(30);
	}

	h4, .h4 {
		font-size: rem-calc(20);
	}
}

.entry-title, .subtitle {
	max-width: rem-calc(950);
}

.subtitle {
	margin-top: bsu(1/3);
	font-size: rem-calc(18);
}



/**
 * Paragraphs
 */
p {
	margin: bsu(2/3) 0 0;

	a {
		@include inline-link;
	}
}


/**
 * Anchors
 */
a, a:visited {
	color: $black;
	text-decoration: none;
}


/**
 * Blockquotes & Cites
 */
blockquote, q {
	position: relative;
	margin: 0;
	padding: bsu(1.5) bsu(1) bsu(1);
	font-family: $font-heading;
	font-size: rem-calc(17);
	font-weight: 600;
	font-style: italic;
	line-height: 1.75;
	quotes: "\201C""\201D""\2018""\2019";

	&:before {
		content: open-quote;
		display: block;
		font-size: 120px;
		line-height: .75;
		font-family: Georgia;
		color: #d8d8d8;
		position: absolute;
		top: rem-calc(-8);
		left: bsu(1/2);
		// width: rem-calc(40);
		// height: rem-calc(32);
		// background: url( '../images/quotes.svg' ) no-repeat top left;
		// background-size: contain;
	}

	a {
		border: 0;
	}

	@include breakpoint( medium ) {
		font-size: rem-calc(21);
		line-height: 1.66;
		padding: rem-calc(58) rem-calc(85) rem-calc(58) rem-calc(58);
		border-left-width: 10px;

		&:before {
			top: auto;
			left: bsu(1);
			width: rem-calc(60);
			height: rem-calc(48);
			z-index: -1;
		}

		cite {
			margin-bottom: rem-calc(-15);
		}
	}

} // blockquote

cite {
	display: block;
	margin-top: bsu(2/3);
	color: $gray-dark;
	font-family: $font-sans-serif;
	font-size: $global-font-size;
	font-style: normal;
	font-weight: 300;
	text-align: right;
	@include no-font-smoothing;

	&:before {
		content: '- ';
	}

	@include breakpoint( medium ) {
		margin-top: bsu(1);
		margin-right: rem-calc(-27);
	}
}


/**
 * Horizontal Rule
 */
hr {
	margin: bsu(2) 0;
	border: none;
}


/**
 * Address
 */
address {
	font-style: normal;
}


/**
 * Lists
 */
ul, ol {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

ul.bulleted,
ol.numbered {
	@include list;
}

ul {
	&.bulleted {
		@include bulleted-list;
	}

	.highlighted-list-wrap &,
	&.highlighted {
		@include highlighted-list;
	}
}

ol.numbered {
	@include numbered-list;
}

/**
 * WYSIWYG content output
 */
.entry-content {
	@include wysiwyg;
}


/**
 * Details
 */
p > small, .detail {
	@include detail;
}
