/**
 * Asset results widget
 *
 * @since   1.0.0
 * @package aaa
 */
.widget-asset-results {
	display: flex;
	flex-flow: row wrap;

	& + & {
		margin-top: bsu(-1/2);
	}

	h2, ul, .all-button-break {
		width: 100%;
	}

	ul {
		flex: 1 0 auto; // was 1 1 auto so it didn't shrink AND not break out
	}

	.view-all-button {
		margin-left: auto;

		&.offset-padding {
			margin-top: bsu(-1/4);
		}
	}
}
