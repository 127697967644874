/**
 * Post assets list widget
 *
 * @since   1.0.0
 * @package aaa
 */
.widget-post-assets {

	.widgettitle, .notice {
		margin-bottom: bsu(1/2);
	}
}
