/**
 * Top strip navigation
 *
 * @since   1.0.0
 * @package aaa
 */
#top-strip {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 55;
	height: $top-strip-height;
	background: $black;
	line-height: $line-height-small;
	font-size: rem-calc(12);

	.wrap, .socials, a {
		display: flex;
	}

	a {
		align-items: center;
		color: $white;
	}


	@include breakpoint( menu-break ) {
		display: flex;
	}


	/**
	 * Admin bar fix
	 */
	body.admin-bar & {

		@include breakpoint( 600px ) {
			top: 46px !important;
		}

		@include breakpoint( 782px ) {
			top: 32px !important;
		}
	}

} // #top-strip


/**
 * Top strip secondary menu
 */
#secondary-menu-top-strip {
	flex-flow: row nowrap;
	margin-left: auto;
	margin-right: bsu(-1/2.5);

	&, > li {
		display: flex;
	}

	> li > a {
		font-weight: 700;
	}

	li {
		position: relative;
		transition-property: background-color;
		@include transition-ease-in-out;

		&:hover {
			background: $blue;
		}
	}

	a {
		padding: bsu(1/3) bsu(1/2.5);

		// &:hover {
		// 	background: $blue;
		// }
	}

	.toggle-sub {
		position: absolute;
		top: 50%;
		right: bsu(1/4);
		pointer-events: none;
		padding: 0;
		border: none;
		background: none;
		transform: translateY( -50% );
	}

	.menu-item-has-children {

		> a {
			padding-right: bsu(3/4);
		}

		&:hover .sub-menu {
			left: 0;
			opacity: 1;
		}
	}

	.menu-item-has-children:hover .sub-menu {
		left: 0;
		opacity: 1;
	}

	.sub-menu {
		position: absolute;
		top: 100%;
		left: -99999px;
		width: 200px;
		background: $blue-light;
		opacity: 0;
		transition: opacity ease-in-out 300ms;

		a {
			@include no-font-smoothing;
		}
	}
}
