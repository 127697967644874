table {
    margin-top: bsu(1);
    width: 100% !important; // need to override inline style
    border: 0;
}

thead {

}

tfoot {
    td {
        text-align: left;
    }
}

tbody {
    tr {
        &:nth-child(even) {
            background: #f4f4f4;
        }
    }
}

tr {

}

th,
td {
    border: 0;
    padding: 0 20px;

    &:first-child {
        padding-left: 20px;
    }

    &:last-child {
        padding-right: 20px;
    }
}

th {
    text-align: left;
}

td {

}