/**
 * Arrow link
 *
 * @since   1.0.0
 * @package aaa
 */
$arrow-link-padding: bsu(1/4);

.arrow-link {
	@include flex-row( true, true );
	margin: $arrow-link-padding (-$arrow-link-padding) 0;
	padding: $arrow-link-padding;
	line-height: $line-height-small;
	font-size: rem-calc(16);
	font-weight: 600;
	color: $blue;

	span:after, .svg-inline--fa {
		@include transition-ease-in-out;
		transition-property: transform, opacity;
	}

	span {
		position: relative;

		&:after {
			content: '';
			position: absolute;
			bottom: 2px;
			left: 0;
			right: 0;
			height: 1px;
			background: $blue; //$white;
			opacity: 0;
		}
	}

	.svg-inline--fa {
		font-size: rem-calc(12);

		&:first-of-type {
			margin: 0 rem-calc(-1) 0 rem-calc(6);
		}
	}

	&.light {
		color: $white;

		span:after {
			background: $white;
		}
	}

	&:hover {

		span:after {
			transform: translateY( 3px );
			opacity: 1;
		}

		.svg-inline--fa {
			transform: translateX( 2px );
		}
	}
}
