/**
 * Post thumbnail/title list
 *
 * @since   1.0.0
 * @package aaa
 */
.post-list {
	padding-top: bsu(1/4);

	li {
		padding: bsu(2/3) 0;
		border-top: 1px solid #d8d8d8;

		&:first-child {
			padding-top: bsu(1/3);
			border-top: none;
		}

		@include breakpoint( medium ) {
			padding-right: bsu(1/2);
		}
	}

	a {
		@include flex-row;
		align-items: flex-start;

		&:hover {
			color: $blue;

			img {
				transform: translateY( rem-calc(-1) );
				box-shadow: 0 3px 16px rgba( $black, 0.18 );
			}
		}
	}

	h3, img {
		@include transition-ease-in-out;
	}

	h3 {
		padding-top: rem-calc(3); // bsu(1/6);
		font-size: rem-calc(14);
		font-family: $font-sans-serif;
		transition-property: color;
	}

	img {
		flex: 0 0 auto;
		margin-right: bsu(2/3);
		transition-property: transform, box-shadow;
	}
}
