/**
 * Responsive Embed
 *
 * @since   1.0.0
 * @package aaa
 */

.embed-container {
	@include responsive-embed-container;
}
