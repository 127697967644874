/**
 * Main Structure
 *
 * @since   1.0.0
 * @package aaa
 */

html, body {
	height: 100%;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

body {
	display: flex;
	flex-direction: column;
	overflow-x: hidden;
}

*,
*:before,
*:after { /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}


/**
 * Wrapper
 */
.wrap {
	@include wrap;

	// &.large {
	// 	width: $wrap-width-large;
	// }
}


/**
 * Main container
 */
#content {
	flex: 1 0 auto;
	padding-top: $header-height;

	@include breakpoint( large ) {
		display: flex;
		flex-flow: row wrap;
		align-content: flex-start;
		position: relative;

		// anything being wrapped inside here can be cancelled now
		@include wrap;

		.wrap {
			width: 100%;
		}
	}

	@include breakpoint( menu-break ) {
		padding-top: $header-height-desk + $top-strip-height;
	}
}


/**
 * Main content container
 */
[role="main"],
aside.sidebar {
	padding: bsu(3/4) 0;

	@include breakpoint( medium ) {
		padding: bsu(1.25) 0;
	}

	@include breakpoint( large ) {
		padding: bsu(2.5) 0 bsu(4);
	}
}


[role="main"] {
	display: block;
	// width: 100%;
	// padding: bsu(3/4) 0;

	&.default .wrap {
		max-width: rem-calc(1020);
	}

	> article {
		// width: 100%; // disabled for asset single
	}

	// @include breakpoint( medium ) {
	// 	padding: bsu(1.25) 0;
	// }

	@include breakpoint( large ) {
		flex: 1 1 50%; // 50% makes it so it isn't too big
		// padding: bsu(2.5) 0 bsu(4);
	}

} // main


aside.sidebar {
	flex: 0 0 40%; // 0 0 33%;

	@include breakpoint( large ) {
		max-width: rem-calc(508);
		margin-left: bsu(1);
	}

	@include breakpoint( xlarge ) {
		flex-basis: 33%;
	}

	@include breakpoint( xxxlarge ) {
		margin-left: bsu(1.125);
	}
}


/**
 * Figure & img fixes
 */
figure {
	margin: 0;
}

img {
	height: auto; /* Make sure images are scaled correctly. */
	max-width: 100%; /* Adhere to container width. */

	&[data-object-fit="cover"] {
		@include cover-image;
	}

	&.size-thumbnail {
		border-radius: $border-radius;
	}
}


/**
 * SVG
 */
svg {
	display: block;
	height: auto;
}
.svg {
	font-size: 0;
}

/*
 * Featherlight
 */
html.with-featherlight {
	overflow: visible;
}