.author-summary {
	display: flex;

	* {
		font-weight: 500;
	}

	.author-summary_title,
	a {
		display: block;
	}

	.author-summary_thumbnail {
		display: inline-block;
		margin-right: bsu(1/2);
		width: 50px;
		height: 50px;
		border-radius: 50%;
		overflow: hidden;
		position: relative;

		img {
			display: block;
			width: 100%;
			height: 100%;
		}
	}

	.author-summary_details {
		font-size: 16px;
		line-height: 1.25;
	}

	.author-summary_name {
		font-weight: normal;
		color: $black;
	}

	.author-summary_title,
	.author-contacts {
		font-size: rem-calc(14);
	}

	.author-summary_title {
		color: $gray-darker;
	}

	.author-contacts {
		margin-top: rem-calc(1);

		a {
			color: $gray-darker;
			@include transition-color( $blue );

			+ a {
				margin-top: rem-calc(2);
			}
		}
	}

	@include breakpoint( medium ) {
		align-items: center;

		.author-summary_details {
			@include flex-row;
		}

		.author-contacts {
			margin-left: bsu(1);
		}
	}

	@include breakpoint( xlarge ) {

		.author-contacts {
			margin-left: bsu(2);
		}
	}
}